import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import VImageMagnifierPlugin from 'v-image-magnifier'
import SiteSwitcher from './pages/yobino/components/SiteSwitcher.vue'
import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'

import JsonCSV from 'vue-json-csv'
const app = createApp(App)

app.component('SiteSwitcher', SiteSwitcher)

app.use(JsonCSV)
app.use(stores)
app.use(router)
app.use(i18n)
app.use(VImageMagnifierPlugin, {
  zoomSize: 150,
  zoomFactor: 2,
})
app.use(createVuestic({ config: vuesticGlobalConfig }))

app.mount('#app')
