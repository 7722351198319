import { defineStore } from 'pinia'
import { Site } from '../pages/yobino/types'
import { fetcher } from '../helpers/fetcher'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userName: localStorage.getItem('userName') ?? '',
      selectedSite: localStorage.getItem('selectedSite') ?? undefined,
      email: localStorage.getItem('email') ?? 'email@obeia.com',
      token: localStorage.getItem('token') ?? '',
      is2FAEnabled: false,
      lang: localStorage.getItem('lang') ?? 'fr',
      sites: undefined as Site[] | undefined,
    }
  },
  getters: {
    getSites(state){
        return state.sites
      }
  },
  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserLang(lang: string) {
      this.lang = lang
      localStorage.setItem('lang', this.lang)
    },

    changeUserName(userName: string) {
      this.userName = userName
      localStorage.setItem('userName', this.userName)
    },

    changeUserEmail(userEmail: string) {
      this.email = userEmail
      localStorage.setItem('email', this.email)
    },

    changeUserSelectedSite(selectedSite: string) {
      this.selectedSite = selectedSite
      localStorage.setItem('selectedSite', this.selectedSite)
    },

    updateToken(token: string) {
      this.token = token
      localStorage.setItem('token', this.token)
    },

    async fetchSites(){
      let sitesObject: Site[] = []
      const sitesFromAPI = await fetcher.get(`${import.meta.env.VITE_API_URL}/yobino/sites`)
      Object.assign(sitesObject, sitesFromAPI)
      this.sites = sitesObject
    },

    logout() {
      localStorage.removeItem('selectedSite')
      localStorage.removeItem('lang')
      localStorage.removeItem('userName')
      localStorage.removeItem('email')
      localStorage.removeItem('token')
    },
  },
})
