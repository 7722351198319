import { useUserStore } from '../stores/user-store'
import Router from '../router'

export const fetcher = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
}

function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    }

    if (body) {
      requestOptions.headers['Content-Type'] = 'application/json'
      requestOptions.body = JSON.stringify(body)
    }
    return fetch(url, requestOptions).then(handleResponse).catch(handleError)
  }
}

function authHeader(url) {
  const user = useUserStore()
  const isLoggedIn = !!user?.token
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL)
  let headers = {}

  if (isLoggedIn && isApiUrl) {
     headers['Authorization'] = `Bearer ${user.token}`
  }

  headers['yobino-app-client'] = `webApp`
  headers['yobino-app-version'] = import.meta.env.VITE_APP_VERSION
  headers['yobino-app-build'] = import.meta.env.VITE_APP_BUILD

  return headers
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const user = useUserStore()
      if ([401, 403].includes(response.status)) {
        console.log('erreur auth')
        user.logout()
        Router.push({ name: 'login' })
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

function handleError(error) {
  console.log(error)
}
