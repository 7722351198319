<template>
  <svg :height="height" alt="Obeia" fill="none" viewBox="0 0 611.12 163.73" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="colorsComputed.start"
      d="M230.17,377q0,18.57-5.66,32.72a65.47,65.47,0,0,1-15.73,23.68,66.76,66.76,0,0,1-23.65,14.4,87.36,87.36,0,0,1-29.69,4.88,88.39,88.39,0,0,1-29.81-4.88A65.21,65.21,0,0,1,102,433.38a66.11,66.11,0,0,1-15.6-23.68Q80.72,395.55,80.72,377t5.79-32.6a68.18,68.18,0,0,1,15.85-23.68,65.62,65.62,0,0,1,23.77-14.52,90.85,90.85,0,0,1,58.75,0,65.9,65.9,0,0,1,23.65,14.52,68.32,68.32,0,0,1,15.85,23.68Q230.17,358.42,230.17,377Zm-27.43,0q0-26.66-12.83-40.58t-34.47-13.92q-21.13,0-34.09,14t-13,40.46q0,26.65,12.84,40.7t34.21,14q21.65,0,34.47-14T202.74,377Z"
      transform="translate(-80.72 -289.16)"
    />
    <path
      :fill="colorsComputed.start"
      d="M316.21,334.62a65.28,65.28,0,0,1,22.14,3.69,49.39,49.39,0,0,1,17.86,10.95A50,50,0,0,1,368,367.58q4.27,11.07,4.28,25.82,0,15-4.41,26.18A47.85,47.85,0,0,1,355.46,438a53.11,53.11,0,0,1-19.25,11,78.79,78.79,0,0,1-24.78,3.69,84.83,84.83,0,0,1-24-3.09,70.55,70.55,0,0,1-17-7.14,25.22,25.22,0,0,1-7.55-6.67,14.91,14.91,0,0,1-2.26-8.56V294.88q1.75-.48,5-1.19a32.73,32.73,0,0,1,7-.72q7.31,0,10.7,2.62t3.39,8.81V342a79,79,0,0,1,12.83-5.12A56,56,0,0,1,316.21,334.62Zm-4.28,20a38.49,38.49,0,0,0-14.34,2.5,48.14,48.14,0,0,0-10.82,5.83v63.3a36.78,36.78,0,0,0,9.82,4.29,50.42,50.42,0,0,0,14.59,1.9q15.35,0,24.91-9.52t9.56-29.51q0-20.22-9.44-29.51T311.93,354.61Z"
      transform="translate(-80.72 -289.16)"
    />
    <path
      :fill="colorsComputed.start"
      d="M421.38,406.25q3.51,13.58,13.71,20.11t26,6.55a57.91,57.91,0,0,0,19.37-3.1,62.26,62.26,0,0,0,14.09-6.66q7.05,3.81,7.05,10.71a10.77,10.77,0,0,1-3.27,7.5,27.49,27.49,0,0,1-8.94,5.95,60.75,60.75,0,0,1-13.33,3.92,90.3,90.3,0,0,1-16.48,1.43,87,87,0,0,1-26.54-3.81,55.72,55.72,0,0,1-20.51-11.42,51,51,0,0,1-13.21-18.8q-4.65-11.19-4.65-25.94,0-14.28,4.53-25.11a51.48,51.48,0,0,1,12.32-18.09,53.19,53.19,0,0,1,18.37-11.06,66.28,66.28,0,0,1,22.65-3.81,62.82,62.82,0,0,1,22,3.69,51.9,51.9,0,0,1,17.11,10.23,45,45,0,0,1,11.07,15.59,49.34,49.34,0,0,1,3.9,19.75q0,6-3,8.69t-8.81,3.69Zm31.2-52.35q-13.84,0-23,8.8t-9.68,25.23l61.89-8.33a29.28,29.28,0,0,0-8.3-18.21Q466.16,353.91,452.58,353.9Z"
      transform="translate(-80.72 -289.16)"
    />
    <path
      :fill="colorsComputed.end"
      d="M531.83,303.68a13.49,13.49,0,0,1,4.52-10.35,16.47,16.47,0,0,1,11.58-4.17c4.69,0,8.47,1.4,11.32,4.17a14.65,14.65,0,0,1,0,20.7q-4.28,4.17-11.32,4.17A16.43,16.43,0,0,1,536.35,314,13.46,13.46,0,0,1,531.83,303.68ZM561,448.85a27,27,0,0,1-4.91,1.31,38.49,38.49,0,0,1-7.17.6q-7.29,0-10.81-2.62t-3.53-8.81V338.67q2-.48,5.16-1.19a32.32,32.32,0,0,1,7.17-.72q7.29,0,10.69,2.62t3.4,9Z"
      transform="translate(-80.72 -289.16)"
    />
    <path
      :fill="colorsComputed.end"
      d="M640.77,334.62q23.38,0,37.23,9.64t13.84,30.34v55.45q0,5.47-2.64,8.45a28.4,28.4,0,0,1-7.17,5.59,73,73,0,0,1-16.86,6.19,97.46,97.46,0,0,1-23.9,2.62q-24.4,0-38.12-9T589.44,417.2q0-15.7,10.69-24T631,382.93l35-3.33v-5.24q0-10.47-7.17-15.23t-20-4.76A71.37,71.37,0,0,0,619.38,357a115.93,115.93,0,0,0-16.61,5.95,17.88,17.88,0,0,1-4.27-4.64,10.93,10.93,0,0,1-1.76-6.07,10.14,10.14,0,0,1,2.14-6.66,16.75,16.75,0,0,1,6.66-4.52,62.06,62.06,0,0,1,16.1-4.88A113.67,113.67,0,0,1,640.77,334.62Zm.5,98.76a62.78,62.78,0,0,0,15.35-1.66q6.54-1.67,9.3-3.57V397.92l-27.42,2.62q-11.58,1.2-17.36,4.88t-5.79,11.31A14.12,14.12,0,0,0,621.64,429Q627.93,433.39,641.27,433.38Z"
      transform="translate(-80.72 -289.16)"
    />
    <path
      :fill="colorsComputed.start"
      d="M167.7,347.62l-12-4.95c-.43,1.58-9.26,35.41,11.43,54.07a40.53,40.53,0,0,0,16.13,8.77,7.78,7.78,0,0,0,5.86-3.4,7.38,7.38,0,0,0-.29-8.06,25.53,25.53,0,0,1-10.25-4.15C167.63,382.25,163.68,365.59,167.7,347.62Z"
      transform="translate(-80.72 -289.16)"
    />
    <path
      :fill="colorsComputed.end"
      d="M143.9,347.62l11.89-4.95c.43,1.58,9.18,35.41-11.32,54.07a40.12,40.12,0,0,1-16,8.77,7.68,7.68,0,0,1-5.8-3.4,7.44,7.44,0,0,1,.28-8.06,25.24,25.24,0,0,0,10.17-4.15C144,382.25,147.88,365.59,143.9,347.62Z"
      transform="translate(-80.72 -289.16)"
    />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: '#3f3f3f',
    end: '#54af68',
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
